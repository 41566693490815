import * as React from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'

import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import InputBase from '@mui/material/InputBase'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import SearchIcon from '@mui/icons-material/Search'
import TwitterIcon from '@mui/icons-material/Twitter'
import { Call as CallIcon, User as UserIcon, Sms as SmsIcon } from 'iconsax-react'

import theme from '../../theme'

import BlackLogoImg from '../../assets/icons/black-logo.svg'
import { ReactComponent as FacebookSvg } from '../../assets/images/facebook.svg'
import { ReactComponent as LinkedinSvg } from '../../assets/images/linkedin.svg'
import { ReactComponent as TwitterSvg } from '../../assets/images/twitter.svg'

const NavItem = styled(props => (
  <Typography
    sx={{
      height: 88,
      lineHeight: '90px',
      boxSizing: 'content-box',
      fontSize: 16,
      fontWeight: 500,
      px: '6px',
      color: props.isActive ? theme.palette.primary.main : theme.palette.grey[800],
      borderBottom: `2px solid ${props.isActive ? theme.palette.secondary.main : 'transparent'}`,
    }}
  >
    {props.label}
  </Typography>
))``
const CTAButton = styled(Button)({
  padding: '15px 42px',
  borderRadius: 4,
  color: 'white',
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '21px',
})
const Heading = styled('h5')({
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '28px',
  color: theme.palette.grey[800],
})
const Text = styled('span')({
  fontSize: 14,
  lineHeight: '26px',
  color: theme.palette.grey[600],
})

const HomeLayout = () => {
  return (
    <Stack>
      <Box sx={{ background: theme.palette.grey[800], color: '#ffffff' }}>
        <Container>
          <Stack direction="row" alignItems="center" sx={{ height: 46 }}>
            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CallIcon size={18} />
              <Typography
                variant="body2"
                sx={{ width: 150, ml: 1 }}
              >
                (907) 555-0101
              </Typography>
            </Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SmsIcon size={18} />
              <Typography variant="body2" sx={{ ml: 1 }}>
                admin@uppmodel.com
              </Typography>
            </Box>

            <IconButton
              sx={{ ml: 'auto', color: 'white' }}
              onClick={() => window.open('https://www.facebook.com/uppmodel', '_blank', 'noreferrer')}
            >
              <FacebookIcon fontSize="small" />
            </IconButton>
            <IconButton
              sx={{ color: 'white' }}
              onClick={() => window.open('https://www.linkedin.com/company/uppmodel', '_blank', 'noreferrer')}
            >
              <LinkedInIcon fontSize="small" />
            </IconButton>
            <IconButton
              sx={{ color: 'white' }}
              onClick={() => window.open('https://twitter.com/uppmodel', '_blank', 'noreferrer')}
            >
              <TwitterIcon fontSize="small" />
            </IconButton>
            <Link to="/login">
              <Button variant="outlined" color="primaryLight" size="small" sx={{ ml: 2 }}>
                Login
              </Button>
            </Link>
            <Link to="/register">
              <Button
                variant="contained"
                color="primaryLight"
                size="small"
                startIcon={<UserIcon size={15} />}
                sx={{ ml: 1, px: 2 }}
              >
                Get A Quote
              </Button>
            </Link>
          </Stack>
        </Container>
      </Box>
      <Box sx={{ background: '#ffffff' }}>
        <Container>
          <Stack direction="row" alignItems="center" sx={{ height: 90 }}>
            <Box component="img" src={BlackLogoImg} alt="logo" sx={{ height: 48 }} />
            <NavLink to="/" style={{ marginLeft: 'auto' }}>
              {({ isActive }) => <NavItem isActive={isActive} label="Home" />}
            </NavLink>
            <NavLink to="/about-us" style={{ marginLeft: '28px' }}>
              {({ isActive }) => <NavItem isActive={isActive} label="About Us" />}
            </NavLink>
            {/* <NavLink to="/shops" style={{ marginLeft: '28px' }}>
              {({ isActive }) => <NavItem isActive={isActive} label="See Shops" />}
            </NavLink> */}
            {/* <NavLink to="/machine-shop-app" style={{ marginLeft: '28px' }}>
              {({ isActive }) => <NavItem isActive={isActive} label="Machine Shop Application" />}
            </NavLink> */}
            <SearchIcon sx={{ ml: 2 }} />
          </Stack>
        </Container>
      </Box>
      <Outlet />
      <Box
        sx={{ background: 'linear-gradient(180deg, rgba(232, 239, 245, 0.6) 0%, rgba(232, 239, 245, 0) 100%)', py: 15 }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Stack spacing={1}>
                <Heading>Join Our Newsletter</Heading>
                <Typography variant="caption" sx={{ color: theme.palette.grey[600] }}>
                  Get the latest news and updates.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  sx={{
                    border: `2px solid ${theme.palette.grey[300]}`,
                    background: 'white',
                    borderRadius: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    px: 2.5,
                    py: 1,
                    flexGrow: 1,
                  }}
                >
                  <SmsIcon size={18} color={theme.palette.grey[400]} />
                  <InputBase
                    sx={{ border: 'none', outline: 'none', p: 0, height: '34px', ml: 1 }}
                    placeholder="Enter your email"
                  />
                </Box>
                <CTAButton variant="contained" color="primaryLight">
                  Sign Up
                </CTAButton>
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ height: `1px`, background: theme.palette.grey[300], my: 7 }} />
          <Grid container spacing={10}>
            <Grid item xs={12} md={6} lg={4}>
              <Stack>
                <Box component="img" src={BlackLogoImg} alt="logo" sx={{ width: 180 }} />
                <Text sx={{ mt: 3, mb: 5 }}>
                  UPPMODEL is a privately owned and operated company. Refer to our{' '}
                  <Link to="/terms-of-use" target="_blank">
                    <Text sx={{ fontWeight: 700 }}>terms of use</Text>
                  </Link>
                </Text>
                <Typography sx={{ fontSize: 15, lineHeight: '21px', fontWeight: 500, color: theme.palette.grey[600] }}>
                  ©Uppmodel. {new Date().getFullYear()} All rights reserved.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Stack>
                <Heading>Legal</Heading>
                <Text sx={{ mt: 4.5, mb: 3 }}>Legal Notices</Text>
                <Text sx={{ mb: 3 }}>Privacy Policy</Text>
                <Text sx={{ mb: 3 }}>FAQs</Text>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Stack>
                <Heading>Contact Us</Heading>
                {/* <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 4.5, mb: 3 }}>
                  <CallIcon size={20} color={theme.palette.primaryLight.main} />
                  <Text>(907) 555-0101</Text>
                </Stack> */}
                <Stack direction="row" alignItems="center" spacing={1}>
                  <SmsIcon size={20} color={theme.palette.primaryLight.main} />
                  <Text>admin@uppmodel.com </Text>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Stack>
                <Heading>Social</Heading>
                <Stack direction="row" justifyContent="space-between" sx={{ ml: -1, mt: 4.5 }}>
                  <IconButton
                    sx={{ color: theme.palette.primaryLight.main }}
                    onClick={() => window.open('https://www.facebook.com/uppmodel', '_blank', 'noreferrer')}
                  >
                    <FacebookSvg />
                  </IconButton>
                  <IconButton
                    sx={{ color: theme.palette.primaryLight.main }}
                    onClick={() => window.open('https://www.linkedin.com/company/uppmodel', '_blank', 'noreferrer')}
                  >
                    <LinkedinSvg />
                  </IconButton>
                  <IconButton
                    sx={{ color: theme.palette.primaryLight.main }}
                    onClick={() => window.open('https://twitter.com/uppmodel', '_blank', 'noreferrer')}
                  >
                    <TwitterSvg />
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Stack>
  )
}

export default HomeLayout
